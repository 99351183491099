import { Commitment, ComputeBudgetProgram, Connection, PublicKey, SystemProgram, TransactionInstruction } from "@solana/web3.js";
import {
  GameResultStatus,
  GameStatus,
  GameTokenStatus,
  GameType,
  HouseStatus,
  LpStatus,
  PlatformStatus,
  PlatformTokenStatus,
  RewardType,
  TokenStatus,
} from "./enums";
import { BN } from "@coral-xyz/anchor";
import { UNIX_DAY_IN_SECONDS } from "./constants";
import Player, { ImpliedRewardInfo, ImpliedRewardForfeit } from "./playerAccount";

export const modifyComputeUnitsIxn = ComputeBudgetProgram.setComputeUnitLimit({
  units: 1_000_000,
});

export const addPriorityFeeIxn = ComputeBudgetProgram.setComputeUnitPrice({
  microLamports: 10_000,
});

export const getJitoTipIx = (
  fromWallet: PublicKey,
  tip: number = 20_000, // tip in lamports
  tipAccount: PublicKey = new PublicKey(
    "96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5" // Jito tip account
  )
): TransactionInstruction => {
  return SystemProgram.transfer({
    fromPubkey: fromWallet,
    toPubkey: tipAccount,
    lamports: tip
  })
}

export async function listenForTransaction(
  connection: Connection,
  txnSignature: string,
  commitment: Commitment = "processed",
  onSuccess?: Function,
  onError?: Function,
) {
  const triageNotification = (notication) => {
    if (notication.err == null) {
      if (onSuccess) {
        onSuccess(txnSignature);
      }
    } else {
      if (onError) {
        onError(txnSignature, notication.err);
      }
    }
  };
  const websocketId = connection.onSignature(txnSignature, triageNotification, commitment);
}

// MAPPING FUNCTIONS NEEEDED AS ANCHOR MAPS ENUMS TO OBJECTS
export const toHouseStatus = (status: object): HouseStatus => {
  if ("active" in status) {
    return HouseStatus.Active;
  } else if ("frozen" in status) {
    return HouseStatus.Frozen;
  } else if ("inFlowsSuspended" in status) {
    return HouseStatus.InFlowsSuspended;
  } else if ("outFlowsSuspended" in status) {
    return HouseStatus.OutFlowsSuspended;
  } else {
    return HouseStatus.Uninitialized;
  }
};

export const toTokenStatus = (status: object): TokenStatus => {
  if ("active" in status) {
    return TokenStatus.Active;
  } else if ("inFlowsSuspended" in status) {
    return TokenStatus.InFlowsSuspended;
  } else if ("outFlowsSuspended" in status) {
    return TokenStatus.OutFlowsSuspended;
  } else {
    return TokenStatus.Inactive;
  }
};

export const toGameStatus = (status: object): GameStatus => {
  if ("active" in status) {
    return GameStatus.Active;
  } else if ("configured" in status) {
    return GameStatus.Configured;
  } else if ("inactive" in status) {
    return GameStatus.Inactive;
  } else if ("noNewBets" in status) {
    return GameStatus.NoNewBets;
  } else {
    return GameStatus.Uninitialized;
  }
};

export const toGameType = (gameType: object): GameType => {
  if ("coinFlip" in gameType) {
    return GameType.CoinFlip;
  } else if ("limbo" in gameType) {
    return GameType.Limbo;
  } else if ("plinko" in gameType) {
    return GameType.Plinko;
  } else if ("dice" in gameType) {
    return GameType.Dice;
  } else if ("roulette" in gameType) {
    return GameType.Roulette;
  } else if ("wheel" in gameType) {
    return GameType.Wheel;
  } else if ("mines" in gameType) {
    return GameType.Mines;
  } else if ("keno" in gameType) {
    return GameType.Keno;
  } else if ("baccarat" in gameType) {
    return GameType.Baccarat;
  } else if ("crash" in gameType) {
    return GameType.Crash;
  } else if ("slotsThree" in gameType) {
    return GameType.SlotsThree;
  } else {
    return GameType.Unspecified;
  }
};

export const toLpStatus = (status: object): LpStatus => {
  if ('active' in status) {
    return LpStatus.Active
  } else if ('inActive' in status) {
    return LpStatus.Inactive
  } else if ('inFlowsSuspended' in status) {
    return LpStatus.InFlowsSuspended
  } else if ('OutFlowsSuspended' in status) {
    return LpStatus.OutFlowsSuspended
  }

  throw new Error("Unknown Lp Status", status)
}

export const toGameTokenStatus = (status: object): GameTokenStatus => {
  if ("active" in status) {
    return GameTokenStatus.Active;
  } else {
    return GameTokenStatus.Inactive;
  }
};

export const toBetStatus = (status: object): GameResultStatus => {
  if ("open" in status) {
    return GameResultStatus.OPEN;
  } else if ("won" in status) {
    return GameResultStatus.WON;
  } else if ("lost" in status) {
    return GameResultStatus.LOST;
  } else if ("push" in status) {
    return GameResultStatus.PUSH;
  } else if ("cancelled" in status) {
    return GameResultStatus.CANCELLED;
  } else {
    throw new Error("Unknown game result status");
  }
};

export const toPlatformTokenStatus = (status: object): PlatformTokenStatus => {
  if ("active" in status) {
    return PlatformTokenStatus.Active;
  } else if ("noNewActivity" in status) {
    return PlatformTokenStatus.NoNewActivity;
  }

  throw new Error("Platform token status not recognised");
};

export const toPlatformStatus = (status: object) => {
  if ("active" in status) {
    return PlatformStatus.Active;
  } else if ("noNewPlayers" in status) {
    return PlatformStatus.NoNewPlayers;
  } else if ("noNewPlayersOrBets" in status) {
    return PlatformStatus.NoNewPlayersOrBets;
  } else {
    return PlatformStatus.Inactive;
  }
};

export function getRewardInfoFromSlots(
  slots: BN[],
  lastRefreshTs: number, // lastRefresh timestamp OR stated rank (i.e. last time they collected a level up)
  nowTs: number, // Current unixtimestamp or implied rank
  rewardType: RewardType,
  playerAccount: Player
): ImpliedRewardInfo {
  var periodIntervalSeconds: number;
  if (rewardType == RewardType.Rakeback || rewardType == RewardType.DailyBonus) {
    periodIntervalSeconds = UNIX_DAY_IN_SECONDS;
  } else if (rewardType == RewardType.WeeklyBonus) {
    periodIntervalSeconds = 7 * UNIX_DAY_IN_SECONDS;
  } else if (rewardType == RewardType.MonthlyBonus) {
    periodIntervalSeconds = 30 * UNIX_DAY_IN_SECONDS;
  } else if (rewardType == RewardType.LevelUpBonus) {
    periodIntervalSeconds = 1;
  } else {
    throw new Error("Unrecognised RewardType");
  }


  const currentPeriodInt: number = Math.floor(nowTs / periodIntervalSeconds);
  const currentPeriodStartTs: number = currentPeriodInt * periodIntervalSeconds;
  const previousPeriodStartTs: number = currentPeriodStartTs - periodIntervalSeconds;

  const currentPeriodSlotIdx: number = currentPeriodInt % 2;
  const alternateSlotIdx: number = (currentPeriodInt + 1) % 2;
  var rewardInfo = {
    rewardType: rewardType,
    currentPeriodStart: currentPeriodStartTs,
    currentPeriodStartDate: new Date(currentPeriodStartTs * 1000),
    currentPeriodEnd: currentPeriodStartTs + (periodIntervalSeconds - 1),
    currentPeriodEndDate: new Date((currentPeriodStartTs + (periodIntervalSeconds - 1)) * 1000),
    amountCurrentlyAccruing: 0,
    amountAvailableToCollect: 0,
    amountImpliedForfeit: 0,
    impliedForfeits: [],
  } as ImpliedRewardInfo;

  if (rewardType == RewardType.LevelUpBonus) {
    // CHECK IF PAST CURRENT RANK XP
    const dueLevelUp = playerAccount.dueLevelUp
    if (dueLevelUp) {
      rewardInfo.amountCurrentlyAccruing = Number(slots[1]);
      rewardInfo.amountAvailableToCollect = Number(slots[0]);
    } else {
      rewardInfo.amountCurrentlyAccruing = Number(slots[0]);
      rewardInfo.amountAvailableToCollect = Number(0);
    }
  } else {
    if (lastRefreshTs >= currentPeriodStartTs) {
      // Up to date - values reflect actual state
      rewardInfo.amountCurrentlyAccruing = Number(slots[currentPeriodSlotIdx]);
      rewardInfo.amountAvailableToCollect = Number(slots[alternateSlotIdx]);
    } else if (lastRefreshTs >= previousPeriodStartTs) {
      // Alternate slot's value is accurate (and can be collected now)
      // currentSlot actually contains two periods ago, and is a forfeit / needs to be overwritten with 0
      rewardInfo.amountCurrentlyAccruing = 0;
      rewardInfo.amountAvailableToCollect = Number(slots[alternateSlotIdx]);

      // NO FOREFITS ON LEVEL UP BONUS...
      rewardInfo.impliedForfeits.push({
        rewardType: rewardType,
        relatesTo: new BN(previousPeriodStartTs),
        valueBase: new BN(slots[currentPeriodSlotIdx]),
        timestamp: new BN(nowTs),
      } as ImpliedRewardForfeit);

    } else {
      // lastRefreshTs < previousPeriodTs
      // And so both slots contain values from >2 periods ago and
      //  so both need to be forfeit and overwritten with 0
      const lastRefreshSlotIdx = lastRefreshTs / periodIntervalSeconds;
      const periodBeforeLastRefreshSlotIdx = (lastRefreshSlotIdx + 1) % 2;
      const lastRefreshStartTs = lastRefreshSlotIdx * periodIntervalSeconds;
      const periodBeforelastRefreshStartTs = lastRefreshStartTs - periodIntervalSeconds;
      rewardInfo.amountCurrentlyAccruing = 0;
      rewardInfo.amountAvailableToCollect = 0;

      // TODO CHECK THIS BLOCK...

      // NO FOREFITS ON LEVEL UP BONUS...

      rewardInfo.impliedForfeits.push({
        rewardType: rewardType,
        relatesTo: new BN(previousPeriodStartTs - periodIntervalSeconds),
        valueBase: new BN(slots[periodBeforeLastRefreshSlotIdx]),
        timestamp: new BN(nowTs),
      } as ImpliedRewardForfeit);

      rewardInfo.impliedForfeits.push({
        rewardType: rewardType,
        relatesTo: new BN(previousPeriodStartTs),
        valueBase: new BN(slots[lastRefreshSlotIdx]),
        timestamp: new BN(nowTs),
      } as ImpliedRewardForfeit);
    }
  }
  return rewardInfo;
}

// METHOD TO SPAM THE RPC IN THE HOPE ONE WILL LAND
export const sendTxnMultipleTimes = async (promise: Promise<{ signature: string }>, numberOfTries: number): Promise<{ signature: string }> => {
  const txns = await Promise.all(new Array(numberOfTries).fill(0).map(() => promise))

  return txns[0]
}

export const toRewardTypeString = (rewardType: RewardType) => {
  switch (rewardType) {
    case RewardType.DailyBonus:
      return 'dailyBonus'
    case RewardType.WeeklyBonus:
      return 'weeklyBonus'
    case RewardType.MonthlyBonus:
      return 'monthlyBonus'
    case RewardType.Rakeback:
      return 'rakeback'
    case RewardType.LevelUpBonus:
      return 'levelUpBonus'
    case RewardType.Referral:
      return 'referral'
    case RewardType.Undefined:
      return 'undefined'
  }
}
/**
 * Returns an empty string if Crypto API or randomUUID is not supported by browser.
 */
export const generateUUID = (): string => {

  if (typeof self.crypto !== "undefined") {
    const cryptoRef = self.crypto;
    return cryptoRef.randomUUID?.();
  }

  return '';
}
