import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { calculateSolanaTPS, checkNetworkStatus } from "../utils/solana/health";
import { HttpStatusCode } from "axios";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";

export enum InternetConnectionStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

const MINIMUM_TPS = 25;

export interface IHealthContext {
  // SOLANA
  solanaHealthy: boolean | undefined;
  solanaTps: number | undefined;
  solanaStatus: HttpStatusCode | undefined;

  // INTERNET
  isOnline: boolean | undefined;
  internetConnectionStatus: InternetConnectionStatus | undefined;
}

export const HealthContext = createContext<IHealthContext>({} as IHealthContext);

interface Props {
  children: any;
}

export const HealthProvider = ({ children }: Props) => {
  // SOLANA
  const [solanaTps, setSolanaTps] = useState<number>();
  const [solanaStatus, setSolanaStatus] = useState<HttpStatusCode>();

  const solanaHealthy = useMemo(() => {
    if (solanaStatus == null || solanaTps == null) {
      return true;
    }

    // if (solanaTps < MINIMUM_TPS || solanaStatus != 200) {
    if (solanaStatus != 200) {
      return false;
    }

    return true;
  }, [solanaStatus, solanaTps]);

  // INTERNET
  const [internetConnectionStatus, setInternetConnectionStatus] =
    useState<InternetConnectionStatus>(
      navigator.onLine ? InternetConnectionStatus.ONLINE : InternetConnectionStatus.OFFLINE,
    );
  const isOnline = useMemo(() => {
    return internetConnectionStatus == InternetConnectionStatus.ONLINE;
  }, [internetConnectionStatus]);

  useEffect(() => {
    async function loadSolanaHealth() {
      try {
        const tpsCalc = await calculateSolanaTPS();
        setSolanaTps(tpsCalc);
      } catch (err) {
        console.warn("Issue loading the solana TPS.", err);
        setSolanaTps(0);
      }

      try {
        const solanaStatus = await checkNetworkStatus();
        setSolanaStatus(solanaStatus);
      } catch (err) {
        console.warn("Issue loading the solana network status", err);
        setSolanaStatus(500);
      }
    }

    loadSolanaHealth();
  }, []);

  useEffect(() => {
    function statusHandler(online: boolean) {
      setInternetConnectionStatus(
        online ? InternetConnectionStatus.ONLINE : InternetConnectionStatus.OFFLINE,
      );
    }

    window.addEventListener("online", () => statusHandler(true));
    window.addEventListener("offline", () => statusHandler(false));

    return () => {
      window.removeEventListener("online", () => statusHandler(true));
      window.removeEventListener("offline", () => statusHandler(false));
    };
  }, []);

  // FOR NETWORK RELATED VALIDATIONS
  const { networkValidation } = useContext(ErrorHandlingContext);

  useEffect(() => {
    if (isOnline == false) {
      networkValidation.addErrorMessage({
        type: ErrorType.NO_INTERNET,
        title: "No Internet",
        message: "Please connect to the internet to use the app.",
      });
    } else {
      networkValidation.removeErrorMessage(ErrorType.NO_INTERNET);
    }
  }, [isOnline]);

  useEffect(() => {
    if (solanaHealthy == false) {
      networkValidation.addErrorMessage({
        type: ErrorType.SOLANA_DOWN,
        title: "Solana Down",
        message: "Solana is currently down, this may affect the working of the app.",
      });
    } else {
      networkValidation.removeErrorMessage(ErrorType.SOLANA_DOWN);
    }
  }, [solanaHealthy]);

  return (
    <HealthContext.Provider
      value={useMemo(
        () => ({
          solanaHealthy: solanaHealthy,
          isOnline: isOnline,
          solanaTps: solanaTps,
          solanaStatus: solanaStatus,
          internetConnectionStatus: internetConnectionStatus,
        }),
        [solanaHealthy, isOnline, solanaTps],
      )}
    >
      {children}
    </HealthContext.Provider>
  );
};
