import {
    BlockhashWithExpiryBlockHeight,
    Commitment,
    ComputeBudgetProgram,
    Connection,
    GetProgramAccountsFilter,
    LAMPORTS_PER_SOL,
    PublicKey,
    RpcResponseAndContext,
    SendOptions,
    SignatureResult,
    SimulatedTransactionResponse,
    Transaction,
    TransactionError,
    TransactionMessage,
    TransactionSignature,
    VersionedTransaction,
  } from "@solana/web3.js";
  import SolanaRpc, { BLOCKHASH_COMMITMENT } from "./rpc";
  import { IChainBalance } from "../../contexts/BalanceContext";
  import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
  import { getMaxPriorityFee } from "./priorityFees";
  import { IdlErrorCode } from "@coral-xyz/anchor/dist/cjs/idl";
  import { IRecentBlockhash } from "../../contexts/NetworkContext";
  import { ZEEBIT_LOOKUP_TABLE } from "../../sdk/constants";
  import { addPriorityFeeIxn, modifyComputeUnitsIxn } from "../../sdk/utils";
  
  export const requestAndConfirmAirdropSol = async (
    client: Connection,
    destination: PublicKey,
    amount: number = LAMPORTS_PER_SOL,
  ): Promise<string> => {
    const latestBlockHash = await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
  
    const signature = await client.requestAirdrop(destination, amount);
  
    await client.confirmTransaction(
      {
        blockhash: latestBlockHash.blockhash,
        lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        signature: signature,
      },
      "processed",
    );
  
    return signature;
  };
  
  export const loadAssociatedTokenBalances = async (
    walletPubkey: string,
    client: Connection,
  ): Promise<IChainBalance[]> => {
    const filters: GetProgramAccountsFilter[] = [
      {
        dataSize: 165, //size of account (bytes)
      },
      {
        memcmp: {
          offset: 32, //location of our query in the account (bytes)
          bytes: walletPubkey, //our search criteria, a base58 encoded string
        },
      },
    ];
    const accounts = await client.getParsedProgramAccounts(TOKEN_PROGRAM_ID, {
      filters: filters,
    });
  
    const balances: IChainBalance[] = [];
  
    accounts.forEach((account) => {
      try {
        const balance = {
          identifier: account.account.data.parsed.info.mint.toString(),
          decimals: account.account.data.parsed.info.tokenAmount.decimals,
          basis: Number(account.account.data.parsed.info.tokenAmount.amount),
          uiAmount: account.account.data.parsed.info.tokenAmount.uiAmount,
        };
  
        balances.push(balance);
      } catch (err) {
        console.warn("Issue parsing token account.", err);
      }
    });
  
    return balances;
  };
  
  export const loadTokenBalance = async (
    wallet: PublicKey,
    mint: PublicKey,
    decimals: number,
    client: Connection,
  ): Promise<IChainBalance> => {
    const ata = await getAssociatedTokenAddress(mint, wallet);
    const tokenMeta = await client.getTokenAccountBalance(ata);
  
    return {
      identifier: mint.toString(),
      decimals: decimals,
      basis: Number(tokenMeta.value.amount),
      uiAmount: Number(tokenMeta.value.uiAmount),
    };
  };
  
  export const loadLamportBalance = async (
    wallet: PublicKey,
    client: Connection,
  ): Promise<IChainBalance> => {
    const lamports = await client.getBalance(wallet);
  
    return {
      decimals: 9,
      uiAmount: lamports / Math.pow(10, 9),
      identifier: "sol",
      basis: lamports,
    };
  };
  
  export const updateErrorContext = (
    txError: TransactionError | null | any[],
    program: string,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
  ) => {
    const parsedErrors: { message: string; name: string; code: number }[] = [];
    if (Array.isArray(txError)) {
      txError.forEach((error) => {
        // TODO - LOOK FOR STANDARD ERRORS HERE
        if (typeof error == "object" && "Custom" in error) {
          const parsed = {};
  
          const errorCode = error["Custom"];
          const parsedError = errorByCodeByProgram?.get(program)?.get(errorCode);
          parsed.message = parsedError?.msg;
          parsed.name = parsedError?.name;
          parsed.code = parsedError?.code;
  
          parsedErrors.push(parsed);
        } else if (typeof error == "number") {
          const parsed = {};
  
          parsed.message = "Solana Base Error";
          parsed.name = "Solana Base Error";
          parsed.code = error;
  
          parsedErrors.push(parsed);
        }
      });
    } else if (txError != null) {
      if (typeof txError == "object" && "Custom" in txError) {
        const parsed = {};
  
        const errorCode: number = txError["Custom"];
        const parsedError = errorByCodeByProgram?.get(program)?.get(errorCode);
        parsed.message = parsedError?.msg;
        parsed.name = parsedError?.name;
        parsed.code = parsedError?.code;
  
        return parsedErrors.push(parsed);
      } else if (typeof txError == "number") {
        const parsed = {};
  
        parsed.message = "Solana base error.";
        parsed.name = "Solana base error.";
        parsed.code = txError;
  
        return parsedErrors.push(parsed);
      }
    }
  
    return parsedErrors;
  };
  
  export const handleSimulatedTransaction = (
    response: RpcResponseAndContext<SimulatedTransactionResponse>,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
  ) => {
    // PROGRAM DEPTH
    const programContext: string[] = [];
    const error = response.value.err; // ERROR CAN BE STRING, OBJECT, OBJECT[]
  
    // IF NO ERROR, SIMULATION PASSED
    if (error == null) {
      return {
        successful: true,
        errors: undefined,
      };
    }
  
    // STRING EXAMPLE
    if (typeof error == "string") {
      if (error == "AccountNotFound") {
        return {
          successful: false,
          errors: [
            {
              name: "Account",
              message: "Account not found.",
              code: 0,
            },
          ],
        };
      } else {
        return {
          successful: false,
          errors: [
            {
              name: "String type errror",
              message: error,
              code: 0,
            },
          ],
        };
      }
    }
  
    const unsignedContext = error?.InstructionError;
  
    const logs = response.value.logs;
    logs?.forEach((log) => {
      const words = log.split(" ");
      if (words.includes("invoke")) {
        programContext.push(words[1]);
      } else if (words.includes("success")) {
        programContext.pop();
      }
    });
  
    const programPubkey =
      programContext != null && programContext.length > 0
        ? programContext[programContext.length - 1]
        : null;
  
    // SHOULD GIVE AN OBJECT WITH MESSAGE, CODE...
    const contextAfterUpdate =
      programPubkey != null
        ? updateErrorContext(unsignedContext, programPubkey, errorByCodeByProgram)
        : null;
  
    if (contextAfterUpdate != null) {
      return {
        successful: false,
        errors: contextAfterUpdate,
      };
    }
  
    if (error != null) {
      return {
        successful: true,
        errors: undefined,
      };
    } else {
      return {
        successful: false,
        errors: [
          {
            name: "Unknown Error",
            code: 0,
            message: error,
          },
        ],
      };
    }
  };
  
  export const getPriorityFeesForComputeUnits = (
    unitsConsumed: number | undefined,
  ) => {
    const maxAdditionalPriorityLamportsForPlayerActions = getMaxPriorityFee(); // LAMPORTS AT TX LEVEL
  
    const computeBudgetSafetyMargin = 0.1;
    const defaultUnitsConsumed = 600_000;
  
    const computeUnitBudget = Math.ceil(
      (unitsConsumed || defaultUnitsConsumed) * (1 + computeBudgetSafetyMargin),
    );
  
    const calculatedFee = Math.floor(
      (maxAdditionalPriorityLamportsForPlayerActions * 1_000_000) /
        computeUnitBudget,
    );
  
    return calculatedFee;
  };
  
  export const confirmTransaction = async (
    signature: string,
    client: Connection,
    blockhash?: IRecentBlockhash,
    commitment?: Commitment,
  ): Promise<string> => {
    try {
      const latestBlockHash =
        blockhash != null
          ? blockhash
          : await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
  
      await client.confirmTransaction(
        {
          blockhash: latestBlockHash.blockhash,
          lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
          signature: signature,
        },
        commitment || "processed",
      );
  
      return signature;
    } catch (err: any) {
      return Promise.reject(err);
    }
  };
  
  export const toVersionedTransaction = async (
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    blockhash?: IRecentBlockhash,
    lookupTablePubkey: PublicKey = ZEEBIT_LOOKUP_TABLE,
  ): Promise<VersionedTransaction> => {
    const lookupTable = (await client.getAddressLookupTable(lookupTablePubkey))
      .value;
  
    if (lookupTable == null) {
      throw new Error("Lookup table not found...");
    }
  
    const latestBlockHash =
      blockhash != null
        ? blockhash
        : await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
  
    // TAKE THE IXNS FROM TX AND CREATE LUT TX
    const messageWithLookupTable = new TransactionMessage({
      payerKey: feePayer,
      recentBlockhash: latestBlockHash.blockhash,
      instructions: transaction.instructions,
    }).compileToV0Message([lookupTable]);
  
    return new VersionedTransaction(messageWithLookupTable);
  };
  
  export const handleSendTransaction = async (
    clients: Connection[], // ALL CLIENTS YOU WANT TO SEND TO
    wallet: {
      // METHOD TO SIGN TX
      signTransaction: (
        tx: Transaction | VersionedTransaction,
      ) => Promise<Transaction | VersionedTransaction>;
    },
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhash?: IRecentBlockhash,
    lookupTable: PublicKey = ZEEBIT_LOOKUP_TABLE,
  ): Promise<string> => {
    try {
      const recentBlockhash =
        blockhash || (await client.getLatestBlockhash(BLOCKHASH_COMMITMENT));
  
      const recentBlock =
        transaction.recentBlockhash ||
        blockhash?.blockhash ||
        recentBlockhash.blockhash;
      // ADD TEST PRIORITY FEES AND COMPUTE UNITS
      const transactionToSimulate = new Transaction();
      transactionToSimulate.recentBlockhash = recentBlock;
      transactionToSimulate.feePayer = transaction.feePayer;
  
      transactionToSimulate.instructions = [
        modifyComputeUnitsIxn,
        addPriorityFeeIxn,
        ...transaction.instructions,
      ];
  
      const transactionWithLookupTableToSimulate = await toVersionedTransaction(
        transactionToSimulate,
        client,
        feePayer,
        recentBlockhash,
        lookupTable,
      );
  
      // SIMULATE
      const simulated = await client.simulateTransaction(
        transactionWithLookupTableToSimulate,
        {
          replaceRecentBlockhash: true,
          sigVerify: false,
        },
      );
      const simulationMeta = handleSimulatedTransaction(
        simulated,
        errorByCodeByProgram,
      );
  
      if (simulationMeta.successful == false) {
        console.error({
          errorInSimulation: true,
          simulatedTxContext: simulated.context,
          simulatedTxContextValue: simulated.value,
          formattedErrors: simulationMeta.errors,
        });
  
        return Promise.reject(simulationMeta.errors);
      }
  
      // WANT TO ACTUALLY SET THE COMPUTE UNITS AND PRIORITY FEES HERE
      const unitsConsumed = (simulated.value.unitsConsumed || 1_000_000) * 1.1;
      // CAN ONLY CALL FOR MAINNET
      const priorityFeeMicroLamports = getPriorityFeesForComputeUnits(unitsConsumed) 
 
        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({ 
            units: unitsConsumed, 
        }); 
 
        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({ 
            microLamports: priorityFeeMicroLamports || 100_000, 
        });
  
      const updatedTransaction = new Transaction();
      updatedTransaction.instructions = [
        modifyComputeUnits,
        addPriorityFee,
        ...transaction.instructions,
      ];
  
      updatedTransaction.recentBlockhash = recentBlock;
      updatedTransaction.feePayer = transaction.feePayer;
  
      // SET ACTUAL COMPUTE UNITS AND PRIORITY FEES
      const transactionWithLookupTable = await toVersionedTransaction(
        updatedTransaction,
        client,
        feePayer,
        recentBlockhash,
        lookupTable,
      );
  
      const signedTx = await wallet.signTransaction(transactionWithLookupTable);
      const serialised = signedTx.serialize();
  
      const signature = await sendUntilSuccess(clients, serialised, {
        skipPreflight: true,
        maxRetries: 0,
      }, recentBlockhash);
  
      return signature;
    } catch (err: any) {
      console.error({ err });
      return Promise.reject(err);
    }
  };
  
  const sendAndConfirmRawTransaction = async (
    connection: Connection,
    rawTransaction: Buffer | Uint8Array,
    sendOptions: SendOptions = {
      skipPreflight: true,
      maxRetries: 0,
    },
    blockhash?: BlockhashWithExpiryBlockHeight
  ): Promise<TransactionSignature> => {
  
    let status: SignatureResult;
  
    const startTime = Date.now();
    while (Date.now() - startTime < 60_000) {
      try {
        const signature = await connection.sendRawTransaction(
          rawTransaction,
          sendOptions
        );
  
        if (blockhash) {
          if (sendOptions.maxRetries === 0) {
            const abortSignal = AbortSignal.timeout(15_000);
            status = (
              await connection.confirmTransaction(
                { abortSignal, signature, ...blockhash },
                sendOptions.preflightCommitment
              )
            ).value;
            abortSignal.removeEventListener("abort", () => { });
          } else {
            status = (
              await connection.confirmTransaction(
                { signature, ...blockhash },
                sendOptions.preflightCommitment
              )
            ).value;
          }
        } else {
          status = (
            await connection.confirmTransaction(
              signature,
              sendOptions.preflightCommitment
            )
          ).value;
        }
  
        if (status.err) {
          throw new ConfirmError(
            `Raw transaction ${signature} failed (${JSON.stringify(status)})`
          );
        }
  
        return signature;
      } catch (e) {
        if (e.name === "AbortError") {
          continue;
        }
        throw e;
      }
    }
  
    throw Error("Transaction failed to confirm in 60s");
  }
  
  class ConfirmError extends Error {
    constructor(message?: string) {
      super(message);
    }
  }
  
  export const sendUntilSuccess = async (
    clients: Connection[],
    serialised: Uint8Array,
    options: SendOptions = {
      skipPreflight: true,
      maxRetries: 0,
    },
    blockhash?: BlockhashWithExpiryBlockHeight,
  ): Promise<string> => {
    const promises = await Promise.allSettled(
      clients.map((client) => {
        return sendAndConfirmRawTransaction(client, serialised, options, blockhash);
      }),
    );
  
    // GO THROUGH ERRORS AND PRINT THEM OUT
    promises.forEach((promise, index) => {
      if (promise.status == "rejected") {
        console.warn(`-----------`);
        console.warn(`Issue with ${clients[index].rpcEndpoint}`);
        console.warn({
          promise,
        });
        console.warn(`-----------`);
      }
    });
  
    // GET THE FIRST ONE THAT SUCCEEDS
    const firstSuccess = promises.find((result) => result.status == "fulfilled");
  
    // IF NONE OF THEM SUCCEED FORMAT ERROR FOR LOGS
    if (firstSuccess == null) {
      const reasons = promises.map((promise) => {
        if ("reason" in promise) {
          return promise?.reason;
        } else {
          return "unknown reason";
        }
      });
      return Promise.reject(
        `All promises failed accross all providers. Reasons=${reasons.join(", ")}`,
      );
    }
  
    if (firstSuccess != null && "value" in firstSuccess) {
      return firstSuccess.value;
    }
  
    return Promise.reject("No Value in successful promise");
  };
  