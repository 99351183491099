import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PublicKey, Transaction } from "@solana/web3.js";
import { Connection } from "@solana/web3.js";
import { BN } from "@coral-xyz/anchor";

import PlayerAccount from "../sdk/playerAccount";
import { PlatformContext } from "./PlatformContext";
import Platform from "../sdk/platform";
import { ProgramContext } from "./ProgramContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { NetworkContext } from "./NetworkContext";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { HouseContext } from "./HouseContext";
import { formatSelfExclusionDate } from "../utils/date/date";
import { WrappedWalletContext } from "./WrappedWalletContext";

export interface IPlayerMeta {
  // USED TO TRACK IF NEW WALLET CONNECTED, WIPE IF SO
  walletPubkey?: string;

  username?: string;
  latestPlatformTerms?: number;
  latestHouseTerms?: number;
  signedTerms?: boolean;
  showUsername?: boolean;

  // EMAILS AND PREFERENCES
  email?: string;
  accountRelatedEmails?: boolean;
  promotionalEmails?: boolean;

  // REFERRALS
  referralAccount?: string;
}

export interface IPlayerContext {
  playerAccount: PlayerAccount | undefined;
  setPlayerAccount: React.Dispatch<React.SetStateAction<PlayerAccount | undefined>>;
  createPlayerAccount: (username: string) => Promise<PlayerAccount | undefined>;
  loadedPlayerAccount: boolean;
  playerMeta: IPlayerMeta | undefined;
  setPlayerMeta: React.Dispatch<React.SetStateAction<IPlayerMeta | undefined>>;
  createPlayerAccountAndAirdropTokens: (
    mint: PublicKey,
    amount: number,
  ) => Promise<PlayerAccount | undefined>;
  clientSeed: string;
  setClientSeed: any;
  counter: number;
  loadPlayerAccountAndStartWs: () => Promise<void>;
  updatePlayer: (
    newUsername: string,
    hidden: boolean | null,
    excludeUntil: BN | null,
  ) => Promise<void>;
  isLevelUpModalClosed: boolean;
  isNewNotificationsAvailable: boolean;
  setIsNewNotificationsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  changeIsLevelUpModalState: (state: boolean) => void;
  rewardsMeta: any | undefined
  handleClaimRewardsAndResponse: () => Promise<any | undefined>
  claims: any[] | undefined
  collects: any[] | undefined
}

export const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

interface Props {
  children: any;
}

export const PlayerProvider = ({ children }: Props) => {
  const [playerAccount, setPlayerAccount] = useState<PlayerAccount>();
  const [counter, setCounter] = useState<number>(0);
  const [loadedPlayerAccount, setLoadedPlayerAccount] = useState(false);
  const { platform } = useContext(PlatformContext);
  const { walletPubkey, solanaRpc } = useContext(WrappedWalletContext);
  const { meta } = useContext(ProgramContext);

  // USED TO HOLD ANY DATA NOT HELD ON THE PLAYER ACC STATE
  const [playerMeta, setPlayerMeta] = useLocalStorage("zeebit-player-meta", "");
  const [clientSeed, setClientSeed] = useLocalStorage("zeebit-client-seed", "1234");
  const [ isLevelUpModalClosed, setIsLevelUpModalClosed ] = useLocalStorage("zeebit-is-level-up-modal-closed", false);
  const [ isLevelUpModalClosedState, setIsLevelUpModalClosedState ] = useState<boolean>(isLevelUpModalClosed);

  // WS
  const playerAccSub = useRef<number>();
  const { client, recentBlockhash, networkCounter } = useContext(NetworkContext);

  const setPlayerAccountWithCounter = useCallback(
    (newPlayerAccount: PlayerAccount) => {
      setPlayerAccount(newPlayerAccount);
      setCounter(counter + 1);
    },
    [setPlayerAccount, counter],
  );

  const setPlayerMetaWithCounter = useCallback(
    (newPlayerMeta: IPlayerMeta) => {
      setPlayerMeta(newPlayerMeta);
      setCounter(counter + 1);
    },
    [setPlayerMeta, counter],
  );

  const loadPlayerAccountAndStartWs = useCallback(async () => {
    try {
      if (platform == null || walletPubkey == null || client == null) {
        return;
      }

      // LOAD PLAYER
      const playerAccount = await PlayerAccount.load(platform, walletPubkey);
      setPlayerAccount(playerAccount);

      if (playerAccount.state == null) {
        return;
      }
      // CLOSE WS IF OPEN
      if (playerAccSub.current != null && client != null) {
        try {
          client.removeAccountChangeListener(playerAccSub.current);
        } catch (err) {
          console.warn("Issue closing player account sub", err);
        }
      }

      const playerSubscription = client.onAccountChange(
        playerAccount.publicKey,
        async (updatedAccountInfo) => {
          try {
            let updatedPlayer = PlayerAccount.loadFromBuffer(
              platform,
              walletPubkey,
              Buffer.from(updatedAccountInfo.data),
            );

            updatedPlayer = await updatedPlayer.loadRewardCalendars()

            setPlayerAccount(updatedPlayer);
          } catch (err) {
            console.warn("Error parsing player acc", err);
          }
        },
        "processed",
      );
      playerAccSub.current = playerSubscription;
    } catch (err) {}
  }, [client, platform, walletPubkey]);

  useEffect(() => {
    async function closePlayerWs() {
      if (playerAccSub.current != null && client != null) {
        try {
          client.removeAccountChangeListener(playerAccSub.current);
        } catch (err) {
          console.warn("Issue closing player account sub", err);
        }
      }
    }

    async function loadPlayerAccount(
      platform: Platform,
      player: PublicKey,
      client: Connection,
      wallet: PublicKey,
    ) {
      try {
        setLoadedPlayerAccount(false);
        const playerAccount = await PlayerAccount.load(platform, player);
        setPlayerAccount(playerAccount);

        if (playerAccount.state == null) {
          return;
        }

        try {
          await closePlayerWs();

          const playerSubscription = client.onAccountChange(
            playerAccount.publicKey,
            async (updatedAccountInfo) => {
              try {
                let updatedPlayer = PlayerAccount.loadFromBuffer(
                  platform,
                  wallet,
                  Buffer.from(updatedAccountInfo.data),
                );
                updatedPlayer = await updatedPlayer.loadRewardCalendars()

                setPlayerAccount(updatedPlayer);
              } catch (err) {
                console.warn("Error parsing player acc", err);
              }
            },
            "processed",
          );
          playerAccSub.current = playerSubscription;
        } catch (err) {}
      } catch (e) {
        console.warn("Issue loading the player account. ", e);
      } finally {
        setLoadedPlayerAccount(true);
      }
    }

    if (platform != null && walletPubkey != null && client != null && platform != null) {
      loadPlayerAccount(platform, walletPubkey, client, walletPubkey);
    } else {
      setPlayerAccount(undefined);
    }

    return () => {
      closePlayerWs();
    };
  }, [platform, walletPubkey, client, platform]);

  const createPlayerAccount = useCallback(
    async (username: string) => {
      if (
        solanaRpc == null ||
        walletPubkey == null ||
        playerAccount == null ||
        platform == null ||
        client == null
      ) {
        console.warn("Solana Rpc is null, or wallet null", solanaRpc, walletPubkey);
        return;
      }

      const exists = await playerAccount.checkPlayerAccountInitialized();
      if (exists) {
        console.warn("Player account already created");
        return;
      }

      const referrer =
        playerMeta != null && playerMeta.referralAccount != null
          ? new PublicKey(playerMeta.referralAccount)
          : undefined;

      const ixn = await playerAccount.initializePlayerAccountIxn(
        username,
        platform.house.latestTermsVersion,
        platform.latestTermsVersion,
        referrer,
        walletPubkey,
      );

      const tx = new Transaction();
      tx.add(ixn);

      const sig = await solanaRpc.sendAndConfirmTransaction(
        tx,
        client,
        walletPubkey,
        meta?.errorByCodeByProgram,
        recentBlockhash,
      );

      await playerAccount?.loadState();
      setPlayerAccount(playerAccount);

      return playerAccount;
    },
    [
      solanaRpc,
      walletPubkey,
      playerAccount,
      platform,
      meta,
      playerMeta,
      recentBlockhash,
      networkCounter,
      client,
    ],
  );

  const createPlayerAccountAndAirdropTokens = useCallback(
    async (mint: PublicKey, amount: number) => {
      if (
        solanaRpc == null ||
        walletPubkey == null ||
        playerAccount == null ||
        platform == null ||
        client == null
      ) {
        console.warn("Solana Rpc is null, or wallet null", solanaRpc, walletPubkey);
        return;
      }

      const tx = new Transaction();

      const exists = await playerAccount.checkPlayerAccountInitialized();

      if (exists == false) {
        const referrer =
          playerMeta != null && playerMeta.referralAccount != null
            ? new PublicKey(playerMeta.referralAccount)
            : undefined;
        const username =
          playerMeta && playerMeta.username && playerMeta.username.length > 0
            ? playerMeta.username
            : "";

        const ixn = await playerAccount.initializePlayerAccountIxn(
          username,
          platform.house.latestTermsVersion,
          platform.latestTermsVersion,
          referrer,
          walletPubkey,
        );
        tx.add(ixn);
      }

      const airdropIx = await playerAccount.requestTokenAirdropIxn(mint, amount);
      tx.add(airdropIx);

      const sig = await solanaRpc.sendAndConfirmTransaction(
        tx,
        client,
        walletPubkey,
        meta?.errorByCodeByProgram,
        recentBlockhash,
      );

      await loadPlayerAccountAndStartWs();

      return playerAccount;
    },
    [
      solanaRpc,
      walletPubkey,
      playerAccount,
      platform,
      playerMeta,
      meta,
      recentBlockhash,
      networkCounter,
      client,
      loadPlayerAccountAndStartWs,
    ],
  );

  const updatePlayer = useCallback(
    async (newUsername: string, hidden: boolean | null = null, excludeUntil: BN | null = null) => {
      // CREATE IX TO UPDATE PLAYER
      const updateIx = await playerAccount?.updatePlayerIxn(
        newUsername,
        walletPubkey,
        hidden,
        excludeUntil,
        undefined,
      );
      const tx = new Transaction();
      tx.add(updateIx);

      const resp = await solanaRpc?.sendAndConfirmTransaction(
        tx,
        client,
        walletPubkey,
        meta?.errorByCodeByProgram,
        recentBlockhash,
      );
    },
    [
      playerAccount,
      solanaRpc,
      client,
      walletPubkey,
      meta,
      counter,
      setPlayerAccountWithCounter,
      setPlayerMeta,
      recentBlockhash,
      networkCounter,
    ],
  );

  // VALIDATE THE PLAYER FOR SELF EXCLUSION, TIME OUT AND UNSIGNED TERMS AND CONDITIONS
  const { playerValidation } = useContext(ErrorHandlingContext);
  const { house } = useContext(HouseContext);

  useEffect(() => {
    // SELF EXCLUSION
    if (
      playerAccount != null &&
      playerAccount.state != null &&
      playerAccount.isPermanentlySelfExcluded == true
    ) {
      playerValidation.addErrorMessage({
        type: ErrorType.SELF_EXCLUSION_ACTIVE,
        message: `Betting is restricted due to Self Exclusion`,
        title: "Permanently self excluded",
      });
    } else {
      playerValidation.removeErrorMessage(ErrorType.SELF_EXCLUSION_ACTIVE);
    }

    // TIME OUT
    if (
      playerAccount != null &&
      playerAccount.state != null &&
      playerAccount.isCurrentlySelfExcluded == true
    ) {
      playerValidation.addErrorMessage({
        type: ErrorType.TIME_OUT_ACTIVE,
        message: `Time Out is active and betting is restricted until ${formatSelfExclusionDate(
          playerAccount.excludeUntil || new Date(),
        )}`,
        title: "Self exclusion active",
      });
    } else {
      playerValidation.removeErrorMessage(ErrorType.TIME_OUT_ACTIVE);
    }

    // UNSIGNED TERMS AND CONDITIONS
    if (house == null || platform == null) {
      return;
    }

    if (playerAccount != null && playerAccount.state != null) {
      const latestPlatformSigned =
        playerAccount.platformTermsVersion == platform.latestTermsVersion;
      const latestHouseSigned = playerAccount.houseTermsVersion == house.latestTermsVersion;

      if (latestHouseSigned == false || latestPlatformSigned == false) {
        playerValidation.addErrorMessage({
          type: ErrorType.TERMS_AND_CONDITIONS_UNSIGNED,
          message: `Missing terms and conditions signing.`,
          title: "Missing terms and conditions signing.",
        });
      } else {
        playerValidation.removeErrorMessage(ErrorType.TERMS_AND_CONDITIONS_UNSIGNED);
      }
    } else {
      if (walletPubkey != null && playerMeta != null && playerMeta.signedTerms == false) {
        playerValidation.addErrorMessage({
          type: ErrorType.TERMS_AND_CONDITIONS_UNSIGNED,
          message: `Missing terms and conditions signing.`,
          title: "Missing terms and conditions signing.",
        });
      } else {
        playerValidation.removeErrorMessage(ErrorType.TERMS_AND_CONDITIONS_UNSIGNED);
      }
    }
  }, [playerAccount, playerMeta, platform, house, playerValidation, counter, walletPubkey]);

  return (
    <PlayerContext.Provider
      value={useMemo(
        () => ({
          playerAccount: playerAccount,
          setPlayerAccount: setPlayerAccountWithCounter,
          createPlayerAccount: createPlayerAccount,
          loadedPlayerAccount: loadedPlayerAccount,
          playerMeta: playerMeta,
          setPlayerMeta: setPlayerMetaWithCounter,
          createPlayerAccountAndAirdropTokens: createPlayerAccountAndAirdropTokens,
          clientSeed: clientSeed,
          setClientSeed: setClientSeed,
          counter: counter,
          loadPlayerAccountAndStartWs: loadPlayerAccountAndStartWs,
          updatePlayer: updatePlayer,
          isLevelUpModalClosed: isLevelUpModalClosedState,
          changeIsLevelUpModalState: undefined,
          rewardsMeta: undefined,
          handleClaimRewardsAndResponse: undefined,
          claims: undefined,
          collects: undefined,
          isNewNotificationsAvailable: undefined,
          setIsNewNotificationsAvailable: undefined
        }),
        [
          playerAccount,
          createPlayerAccount,
          loadedPlayerAccount,
          playerMeta,
          createPlayerAccountAndAirdropTokens,
          clientSeed,
          setPlayerMetaWithCounter,
          counter,
          setPlayerAccountWithCounter,
          playerValidation,
          loadPlayerAccountAndStartWs,
          updatePlayer
        ],
      )}
    >
      {children}
    </PlayerContext.Provider>
  );
};
