import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { BaseModal } from "./BaseModal";
import { PlatformContext } from "../contexts/PlatformContext";
import { PlayerContext } from "../contexts/PlayerContext";
import { HouseContext } from "../contexts/HouseContext";
import { TermsAndConditionsStep } from "./registration/TermsAndConditions";
import { BalanceContext } from "../contexts/BalanceContext";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";

interface IRegistrationModalProps {
  open: boolean;
  closeModal: Function;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export const RegistrationModal: React.FC<IRegistrationModalProps> = ({
  open,
  closeModal,
  currentStep,
  setCurrentStep,
}) => {
  // QUICK FIX FOR ALPHA GATING WALLET CONNECTED
  const { walletPubkey } = useContext(WrappedWalletContext);
  const { solBalances } = useContext(BalanceContext);
  const hasSolTokens = (solBalances?.native?.uiAmount || 0) > 0;

  useEffect(() => {
    if (walletPubkey == null) {
      closeModal();
    }
  }, [walletPubkey]);

  const { playerMeta, setPlayerMeta } = useContext(PlayerContext);
  const { house } = useContext(HouseContext);
  const { platform } = useContext(PlatformContext);

  const updateLatestTerms = useCallback(() => {
    const meta = playerMeta || {};
    meta.latestHouseTerms = house?.latestTermsVersion;
    meta.latestPlatformTerms  = platform?.latestTermsVersion as number | undefined;
    meta.signedTerms = true;

    setPlayerMeta(meta);
  }, [house, platform, playerMeta]);

  const steps = useMemo(() => {
    return [
      <TermsAndConditionsStep
        setLatestTermsVersion={() => {
          updateLatestTerms();
          closeModal();
        }}
        onClose={() => closeModal()}
      />
    ];
  }, [currentStep, hasSolTokens]);

  return (
    <BaseModal open={open} hideModal={closeModal}>
      <ModalWrapper>{steps[currentStep]}</ModalWrapper>
    </BaseModal>
  );
}

interface IModalWrapper {
  children: any;
}

const ModalWrapper = ({ children }: IModalWrapper) => {
  return (
    <div className="flex w-[80vw] md:w-[560px] flex-col items-start gap-y-5 rounded-lg bg-gray-800">
      <div className="flex flex-col justify-center items-center gap-y-6 self-stretch">

        {/* UI FOR EACH INDIVIDUAL STEP */}
        {children}
      </div>
    </div>
  );
};
