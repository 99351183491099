import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import House from "../sdk/house";
import Platform, { IPlatformToken } from "../sdk/platform";
import { HouseContext } from "./HouseContext";
import {
  PLATFORM_PUBKEY,
  PLATFORM_STATUS_TAKING_BETS,
  PLATFORM_TOKEN_STATUS_TAKING_BETS,
} from "../sdk/constants";
import { BalanceContext, ITokenCheckMeta } from "./BalanceContext";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";
import { getPlatformTokens } from "../utils/config/utils";
import { defaultNetwork } from "../utils/chain/network";
import { IRankBenefit } from "../sdk/platformRanks";
import PlatformRankNames from "./../config/platform_ranks.json";

export interface IPlatformRank {
  benefit: IRankBenefit;
  threshold: number;
  name: string;
}

export interface IPlatformValidation {
  takingBets: boolean;
  tokenTakingBets: boolean;
  token: IPlatformToken | undefined;
}

export interface IPlatformContext {
  platform: Platform | undefined;
  platformLoaded: boolean;
  tokensByIdentifier: Map<string, ITokenCheckMeta> | undefined;
  tokensByIdentifierLoaded: boolean;
  platformRanks: IPlatformRank[] | undefined;
  loadPlatform: (house: House) => Promise<void>
}

export const PlatformContext = createContext<IPlatformContext>({} as IPlatformContext);

interface Props {
  children: any;
}

export const PlatformProvider = ({ children }: Props) => {
  const [platform, setPlatform] = useState<Platform>();
  const [platformRanks, setPlatformRanks] = useState<IPlatformRank[]>();
  const [platformLoaded, setPlatformLoaded] = useState(false);
  const [validation, setValidation] = useState<IPlatformValidation>();

  const { house } = useContext(HouseContext);
  const { selectedTokenMeta } = useContext(BalanceContext);
  const [tokenByIdentifier, setTokenByIdentifier] = useState<Map<string, ITokenCheckMeta>>();

  // LOAD THE PLATFORM RANKS
  useEffect(() => {
    async function loadPlatformRanks(platform: Platform) {
      try {
        const platformRanks = await platform.loadPlatformRanks();
        const benefits = platformRanks.rankBenefits;
        const thresholds = platformRanks.rankThresholds;
        const names = PlatformRankNames;
        const hasPlumbableMetas =
          benefits != null &&
          thresholds != null &&
          names != null &&
          benefits.length == thresholds.length &&
          benefits.length == names.length;

        // CHECK ALL ARE VALID AND SAME LENGHTS
        if (hasPlumbableMetas == false) {
          setPlatformRanks(undefined);
        } else {
          const platformRankMetas = benefits?.map((benefit, index) => {
            return {
              benefit: benefit,
              threshold: thresholds[index],
              name: names[index],
            };
          });

          setPlatformRanks(platformRankMetas);
        }
      } catch (err) {
        console.warn("Error loading platform ranks", err);
      }
    }

    if (platform == null || platform.state == null) {
      return;
    }

    loadPlatformRanks(platform);
  }, [platform]);

  useEffect(() => {
    if (platform == null || selectedTokenMeta == null) {
      return;
    }
    const selectedTokenString = selectedTokenMeta.mint;

    const platformToken = platform.tokens.find((token) => {
      return token.pubkey == selectedTokenString;
    });

    setValidation({
      takingBets: platform.status != null && PLATFORM_STATUS_TAKING_BETS.includes(platform.status),
      tokenTakingBets:
        platformToken?.status != null &&
        PLATFORM_TOKEN_STATUS_TAKING_BETS.includes(platformToken.status),
      token: platformToken,
    });
  }, [platform, selectedTokenMeta]);

  const { platformValidation } = useContext(ErrorHandlingContext);

  useEffect(() => {
    if (validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      platformValidation.addErrorMessage({
        type: ErrorType.PLATFORM_NOT_ACTIVE,
        title: "Platform not active",
        message: "The platform is not currently taking bets.",
      });
    } else {
      platformValidation.removeErrorMessage(ErrorType.PLATFORM_NOT_ACTIVE);
    }

    if (validation.tokenTakingBets == false) {
      platformValidation.addErrorMessage({
        type: ErrorType.PLATFROM_TOKEN_NOT_ACTIVE,
        title: "Token not active for platform",
        message: "Token inactive for platform.",
      });
    } else {
      platformValidation.removeErrorMessage(ErrorType.PLATFROM_TOKEN_NOT_ACTIVE);
    }
  }, [validation]);

  const loadPlatformRanks = useCallback(async (platform: Platform) => {
    try {
      const platformRanks = await platform.loadPlatformRanks();
      const benefits = platformRanks.rankBenefits;
      const thresholds = platformRanks.rankThresholds;
      const names = PlatformRankNames;
      const hasPlumbableMetas =
        benefits != null &&
        thresholds != null &&
        names != null &&
        benefits.length == thresholds.length &&
        benefits.length == names.length;

      // CHECK ALL ARE VALID AND SAME LENGHTS
      if (hasPlumbableMetas == false) {
        setPlatformRanks(undefined);
      } else {
        const platformRankMetas = benefits?.map((benefit, index) => {
          return {
            benefit: benefit,
            threshold: thresholds[index],
            name: names[index],
          };
        });

        setPlatformRanks(platformRankMetas);
        
        return platformRankMetas
      }
    } catch (err) {
      console.warn("Error loading platform ranks", err);
    }
  }, [])

  const loadPlatform = useCallback(async (house: House) => {
    try {
      // TODO - UPDATE WITH DERIVATION
      const newPlatform = await Platform.load(house, PLATFORM_PUBKEY);
      const platformRanks = await loadPlatformRanks(newPlatform)
      if (platformRanks != null) {
        newPlatform.setPlatformRanks(platformRanks)
      }

      setPlatform(newPlatform);
    } catch (e) {
      console.warn(`Issue loading platform from chain.`, e);
    } finally {
      setPlatformLoaded(true);
    }
  }, [loadPlatformRanks])

  useEffect(() => {
    if (house == null) {
      return;
    }

    loadPlatform(house);
  }, [house, loadPlatform]);

  const [tokensByIdentifierLoaded, setTokensByIdentifierLoaded] = useState(false);

  useEffect(() => {
    if (house == null || platform == null) {
      return;
    }

    const contexts = getPlatformTokens(defaultNetwork);

    const tokenMetaByMint = new Map<string, ITokenCheckMeta>();

    // UPDATE WITH HOUSE TOKENS
    house.tokens.forEach((token) => {
      tokenMetaByMint.set(token.pubkey, {
        houseToken: token,
        platformToken: undefined,
        context: undefined,
      });
    });

    // UPDATE WITH PLATFORM TOKENS
    platform.tokens.forEach((token) => {
      if (tokenMetaByMint.has(token.pubkey)) {
        let meta = tokenMetaByMint.get(token.pubkey);
        if (meta != null) {
          meta.platformToken = token;
          tokenMetaByMint.set(token.pubkey, meta);
        }
      }
    });

    // ADD CONTEXT
    contexts.forEach((context) => {
      if (tokenMetaByMint.has(context.pubkey)) {
        let meta = tokenMetaByMint.get(context.pubkey);
        if (meta != null) {
          meta.context = context;
          tokenMetaByMint.set(context.pubkey, meta);
        }
      }
    });

    setTokensByIdentifierLoaded(true);
    setTokenByIdentifier(tokenMetaByMint);
  }, [house, platform]);

  return (
    <PlatformContext.Provider
      value={useMemo(
        () => ({
          platform: platform,
          platformRanks: platformRanks,
          tokensByIdentifier: tokenByIdentifier,
          platformLoaded: platformLoaded,
          tokensByIdentifierLoaded: tokensByIdentifierLoaded,
          loadPlatform: loadPlatform
        }),
        [platform, platformRanks, tokenByIdentifier, platformLoaded, tokensByIdentifierLoaded, loadPlatform],
      )}
    >
      {children}
    </PlatformContext.Provider>
  );
};
