import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useContext, useCallback, useMemo } from "react";
import { Airdrop } from "../components/toast/airdrop/Airdrop";
import { AggregatedBalancesContext, IMergedToken } from "../contexts/AggregatedBalancesContext";
import { NetworkContext } from "../contexts/NetworkContext";
import { PlayerContext } from "../contexts/PlayerContext";
import { ToasterContext } from "../contexts/ToasterContext";
import SVG from "react-inlinesvg";
import SolIcon from "../assets/icons/sol.svg";
import { BASE_TOAST_CONFIG } from "../components/toast/BaseToast";
import { requestAndConfirmAirdropSol } from "../utils/solana/utils";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";

export const useTokenAirdrop = () => {
  const { client } = useContext(NetworkContext);
  const { solanaRpc, walletPubkey } = useContext(WrappedWalletContext);
  const { solBalances } = useContext(AggregatedBalancesContext);
  const { createPlayerAccountAndAirdropTokens, playerAccount } = useContext(PlayerContext);
  const toast = useContext(ToasterContext);

  const airdropSol = useCallback(
    async (amount: number) => {
      if (solanaRpc == null) {
        throw new Error("Issue with solana rpc");
      } else if (client == null) {
        throw new Error("Issue with solana client");
      } else if (walletPubkey == null) {
        throw new Error("Wallet not connected");
      }
      return await requestAndConfirmAirdropSol(client, walletPubkey, amount);
    },
    [walletPubkey, client, solanaRpc],
  );

  const showAirdropToast = (
    type: "success" | "error",
    token?: { icon: any; amount: number },
    message?: string,
  ) => {
    toast(<Airdrop type={type} token={token} message={message} />, BASE_TOAST_CONFIG);
  };

  const airdropSolWithCallback = useCallback(
    async (
      onStart: Function | undefined,
      onSuccess: Function | undefined,
      onError: Function | undefined,
    ) => {
      if (onStart) {
        onStart();
      }

      try {
        await airdropSol(LAMPORTS_PER_SOL);
        showAirdropToast("success", {
          icon: <SolIcon />,
          amount: 1,
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.warn(err);
        showAirdropToast(
          "error",
          undefined,
          "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
        );
        if (onError) {
          onError();
        }
      }
    },
    [airdropSol],
  );

  const handleAirdrop = useCallback(
    async (
      selectedToken: IMergedToken | undefined,
      onStart: Function | undefined,
      onError: Function | undefined,
      onSuccess: Function | undefined,
    ) => {
      if (onStart != null) {
        onStart();
      }

      try {
        const isRealSol = selectedToken?.context?.isNative == true;
        if (isRealSol == true) {
          try {
            await airdropSol(LAMPORTS_PER_SOL);
          } catch (err) {
            showAirdropToast(
              "error",
              undefined,
              "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
            );
            if (onError != null) {
              onError();
            }

            return;
          }

          showAirdropToast("success", {
            icon: <SVG height={16} width={16} src={selectedToken?.context?.imageDarkSvg || ""} />,
            amount: 1,
          });
          if (onSuccess != null) {
            onSuccess();
          }
        } else {
          const solBalance = solBalances?.native?.basis || 0;
          if (solBalance < 5000) {
            try {
              await airdropSol(LAMPORTS_PER_SOL);
            } catch (err) {
              showAirdropToast(
                "error",
                undefined,
                "Issue airdropping sol. There is a rate limit on airdrops, and may be the issue here.",
              );

              if (onError != null) {
                onError();
              }

              return;
            }
          }
          const amountUI = selectedToken?.context?.airdrop || 100;
          const amountBasis = amountUI * Math.pow(10, selectedToken?.house?.decimals || 6);

          await createPlayerAccountAndAirdropTokens(
            new PublicKey(selectedToken?.context?.pubkey || ""),
            amountBasis,
          );

          showAirdropToast("success", {
            icon: <SVG height={16} width={16} src={selectedToken?.context?.imageDarkSvg || ""} />,
            amount: amountUI,
          });
          if (onSuccess != null) {
            onSuccess();
          }
        }
      } catch (err) {
        console.warn("Issue airdropping tokens", err);
        showAirdropToast("error");
        if (onError) {
          onError();
        }
      }
    },
    [
      playerAccount,
      walletPubkey,
      client,
      airdropSol,
      solBalances,
      createPlayerAccountAndAirdropTokens,
    ],
  );

  return useMemo(() => {
    return {
      handleAirdrop: handleAirdrop,
      airdropSol: airdropSolWithCallback,
    };
  }, [handleAirdrop, airdropSolWithCallback]);
};
