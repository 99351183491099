import { FC } from "react";
import {
  WalletDisconnectButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { DivvyUser } from "../../types/divvy";
require("@solana/wallet-adapter-react-ui/styles.css");

interface Props {
  user?: DivvyUser;
}
export const SolanaConnect = ({ user }: Props) => {
  return (
    <WalletModalProvider>
      <WalletMultiButton>{user?.username}</WalletMultiButton>
    </WalletModalProvider>
  );
};
