import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import House, { IHouseToken } from "../sdk/house";
import { ProgramContext } from "./ProgramContext";
import {
  HOUSE_PUBKEY,
  HOUSE_STATUS_TAKING_BETS,
  HOUSE_TOKEN_STATUS_TAKING_BETS,
} from "../sdk/constants";
import { Program } from "@coral-xyz/anchor";
import { BalanceContext } from "./BalanceContext";
import { ErrorHandlingContext } from "./ErrorHandlingContext";
import { ErrorType } from "../types/error";

export interface IHouseValidation {
  takingBets: boolean;
  tokenTakingBets: boolean;
  availableTradingBalance: number;
  token: IHouseToken | undefined;
}

export interface IHouseContext {
  house: House | undefined;
  houseToken: IHouseToken | undefined;
  houseLoaded: boolean;
  loadHouse: (program?: Program) => Promise<void>
}

export const HouseContext = createContext<IHouseContext>({} as IHouseContext);

interface Props {
  children: any;
}

export const HouseProvider = ({ children }: Props) => {
  const [house, setHouse] = useState<House>();
  const [houseLoaded, setHouseLoaded] = useState(false);
  const { meta } = useContext(ProgramContext);
  const { selectedTokenMeta } = useContext(BalanceContext);

  // METRICS USED IN VALIDATIONS - KEEP THE STATUS
  const [houseToken, setHouseToken] = useState<IHouseToken>();
  const [validation, setValidation] = useState<IHouseValidation>();

  useEffect(() => {
    if (house == null || selectedTokenMeta == null) {
      return;
    }
    const selectedTokenString = selectedTokenMeta.mint;

    const houseToken = house.tokens.find((token) => {
      return token.pubkey == selectedTokenString;
    });

    setHouseToken(houseToken);

    setValidation({
      takingBets: house.status != null && HOUSE_STATUS_TAKING_BETS.includes(house.status),
      tokenTakingBets:
        houseToken?.status != null && HOUSE_TOKEN_STATUS_TAKING_BETS.includes(houseToken.status),
      availableTradingBalance: houseToken?.availableTradingBalance || 0,
      token: houseToken,
    });
  }, [house, selectedTokenMeta]);

  const loadHouse = useCallback(async (program?: Program) => {
    try {
      let cashierProgram = program != null ? program: meta?.cashierProgram
      if (cashierProgram == null) {
        throw Error("Cashier Program null")
      }

      const house = await House.load(cashierProgram, HOUSE_PUBKEY);

      setHouse(house);
    } catch (e) {
      console.warn(`Issue loading the house from chain.`, e);
    } finally {
      setHouseLoaded(true);
    }
  }, [meta?.cashierProgram])

  useEffect(() => {
    if (meta == null || meta.cashierProgram == null) {
      return;
    }

    loadHouse(meta.cashierProgram);
  }, [meta, loadHouse]);

  const errorHandling = useContext(ErrorHandlingContext);

  // VALIDATE THE HOUSE STATUS IS OPEN - Disable button, show message above button
  useEffect(() => {
    if (house == null || validation == null) {
      return;
    }

    if (validation.takingBets == false) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_NOT_ACTIVE,
        title: "House not active",
        message: "The house is not currently taking bets.",
      });
    } else {
      errorHandling.houseValidation.removeErrorMessage(ErrorType.HOUSE_NOT_ACTIVE);
    }

    if (validation.tokenTakingBets == false) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_TOKEN_NOT_ACTIVE,
        title: "Token not active for house",
        message: "Token inactive for house.",
      });
    } else {
      errorHandling.houseValidation.removeErrorMessage(ErrorType.HOUSE_TOKEN_NOT_ACTIVE);
    }

    if (validation.availableTradingBalance <= 0) {
      errorHandling.houseValidation.addErrorMessage({
        type: ErrorType.HOUSE_TOKEN_BALANCE_EMPTY,
        title: "House token balance empty",
        message: "There is no available trading balance for the house in this token.",
      });
    } else {
      errorHandling.houseValidation.removeErrorMessage(ErrorType.HOUSE_TOKEN_BALANCE_EMPTY);
    }
  }, [validation, house]);

  return (
    <HouseContext.Provider
      value={useMemo(
        () => ({
          house: house,
          houseToken: houseToken,
          houseLoaded: houseLoaded,
          loadHouse: loadHouse
        }),
        [house, houseToken, houseLoaded, loadHouse],
      )}
    >
      {children}
    </HouseContext.Provider>
  );
};
