import { NetworkType, defaultNetwork } from "../chain/network";

export enum SolanaExplorer {
  SOLANA_FM='SOLANA.FM',
  SOL_SCAN='SOL SCAN',
  SOLANA_EXPLORER='SOLANA EXPLORER'
}

export enum ExplorerTransactionType {
  ADDRESS = "address",
  TRANSACTION = "tx",
  BLOCK = "block",
}

const getClusterName = (network: string, explorer: SolanaExplorer) => {
  switch (network) {
    case NetworkType.DEVNET:
      switch (explorer) {
        case SolanaExplorer.SOLANA_FM:
          return "devnet-solana";
        case SolanaExplorer.SOLANA_EXPLORER:
          return "devnet";
        case SolanaExplorer.SOL_SCAN:
          return "devnet"
      }
    case NetworkType.TESTNET:
      switch(explorer) {
        case (SolanaExplorer.SOLANA_FM):
          return "testnet-solana";
        case SolanaExplorer.SOLANA_EXPLORER:
          return "testnet";
        case SolanaExplorer.SOL_SCAN:
          return "testnet"
      }
    default:
      console.error("Not a valid network type for solana explorer cluster name. Type: ", network);
      return "devnet-solana";
  }
};

export const getExplorerUrl = (
  pubkeyOrBlock: string,
  network: NetworkType = defaultNetwork,
  transactionType: ExplorerTransactionType = ExplorerTransactionType.ADDRESS,
  selectedExplorer: SolanaExplorer = SolanaExplorer.SOLANA_FM
) => {
  switch(selectedExplorer) {
    case SolanaExplorer.SOLANA_FM:
      return `https://solana.fm/${transactionType}/${pubkeyOrBlock}${
        network == NetworkType.MAINNET ? "?cluter=mainnet-alpha" : "?cluster=" + getClusterName(network, selectedExplorer)
      }`;
    case SolanaExplorer.SOL_SCAN:
      return `https://solscan.io/${transactionType == ExplorerTransactionType.ADDRESS ? "account": transactionType}/${pubkeyOrBlock}${
        network == NetworkType.MAINNET ? "" : "?cluster=" + getClusterName(network, selectedExplorer)
      }`
    case SolanaExplorer.SOLANA_EXPLORER:
      return `https://explorer.solana.com/${transactionType + "/"}${pubkeyOrBlock}${
        network == NetworkType.MAINNET ? "" : "?cluster=" + getClusterName(network, selectedExplorer)
      }`
    default:
      console.warn(`Explorer not recognised Explorer: ${selectedExplorer}, TransactionType: ${transactionType}`)
      return `https://explorer.solana.com/${transactionType == ExplorerTransactionType.ADDRESS ? "": transactionType + "/"}${pubkeyOrBlock}${
        network == NetworkType.MAINNET ? "" : "?cluster=" + getClusterName(network, selectedExplorer)
      }`
  }
};
