import React, { useState, useMemo, useCallback, useContext } from "react";

import Button from "../../components/common/button/Button";
import { TitleAndSubTitle } from "./common/TitleAndSubtitle";
import { Term } from "./common/Term";
import { SCROLLBAR_CLASSES_BLACK } from "../../styles/commonClasses";
import termsHtml from "./../../static/terms/terms.html";
import { WrappedWalletContext } from "../../contexts/WrappedWalletContext";

var template = { __html: termsHtml };

const TERMS = [
  "I am not accessing this platform from a prohibited jurisdiction or any region where the activities on this platform may be prohibited.",
  "I understand that I retain full custody of my wallet and Digital assets at all times, and that this application acts only as an interface with a decentralised protocol.",
  "I have read, understood and accepted the terms as set out here.",
];

interface ITermsAndConditionsStepProps {
  onClose: Function;
  setLatestTermsVersion: Function;
}

export const TermsAndConditionsStep: React.FC<ITermsAndConditionsStepProps> = ({
  setLatestTermsVersion,
  onClose
}) => {
  const [term1, setTerm1] = useState(false);
  const [term2, setTerm2] = useState(false);
  const [term3, setTerm3] = useState(false);
  const { disconnect } = useContext(WrappedWalletContext);

  const acceptedTerms = useMemo(() => {
    return term1 && term2 && term3;
  }, [term1, term2, term3]);

  const handleDisconnect = useCallback(() => {
    disconnect();
    onClose();
  }, []);

  const handleAcceptTerms = useCallback(() => {
    setLatestTermsVersion();
  }, []);

  return (
    <div className="flex w-full flex-col gap-y-3">
      <TitleAndSubTitle
        title="Terms and Conditions"
        subtitle="Review and agree to some terms before joining the community"
        className="text-gray-50"
      />
      <div
        className={`
          overflow-y-scroll h-[30vh] md:h-[360px] p-3 justify-center rounded-lg bg-global-bg mt-3
          font-sm text-gray-400 font-normal ${SCROLLBAR_CLASSES_BLACK}
        `}
      >
        <div dangerouslySetInnerHTML={template} />
      </div>
      <div
        className={`
          flex flex-col items-start gap-y-2 self-stretch mt-2
          [&>div]:rounded-md [&>div]:bg-gray-600 [&>div]:p-3 [&>div>div]:self-start
          [&>div]:text-gray-50
        `}
      >
        <Term text={TERMS[0]} setAccepted={setTerm1} accepted={term1} />
        <Term text={TERMS[1]} setAccepted={setTerm2} accepted={term2} />
        <Term text={TERMS[2]} setAccepted={setTerm3} accepted={term3} />
      </div>
      <div className="flex items-start gap-x-3 self-stretch">
        <Button className="flex-1 bg-gray-500" variant="gray" size="md" onClick={handleDisconnect}>
          Close
        </Button>
        <Button
          className="flex-1 disabled:bg-gray-600 disabled:text-gray-300"
          variant={acceptedTerms ? "primary" : "secondary"}
          size="md"
          disabled={!acceptedTerms}
          onClick={handleAcceptTerms}
        >
          Agree & Sign
        </Button>
      </div>
    </div>
  );
};
