import {
  Connection,
  PublicKey,
  Transaction,
  Commitment,
} from "@solana/web3.js";
import { SafeEventEmitterProvider } from "@web3auth/base";
import { SolanaWallet } from "@web3auth/solana-provider";
import { IChainBalance } from "../../contexts/BalanceContext";
import { ZEEBIT_LOOKUP_TABLE } from "../../sdk/constants";
import { IdlErrorCode } from "@coral-xyz/anchor/dist/cjs/idl";
import { IRecentBlockhash } from "../../contexts/NetworkContext";
import { confirmTransaction, handleSendTransaction, loadAssociatedTokenBalances, loadLamportBalance, loadTokenBalance } from "./utils";
import { ENV_NAME, RPC_WRITE_ENDPOINTS } from "../env/env";

export const IS_MAINNET: boolean = ENV_NAME == 'MAINNET'

export const RPC_WRITE_ENPOINTS = RPC_WRITE_ENDPOINTS.split(',')

export enum SolanaTransactionType {
  SOL_TRANSFER = "Sol Transfer",
  USDC_TRANSFER = "Token Transfer",
  AVER_TX = "Aver Transaction",
  THIRD_PARTY_TX = "Third Party Transaction",
  TOKEN_TRANSFER = "Token Transfer",
  UNKNOWN = "Unknown Transaction",
}

export enum SolanaTransactionStatus {
  SUCCESS = "Success",
  FAILED = "Failed",
  UNKNOWN = "Unknown",
}

export interface ITokenChange {
  mint: string;
  change: number;
}

export interface ISolanaTransaction {
  signature: string;
  date: Date;
  type: SolanaTransactionType;
  lamportChange: number;
  tokenChanges: ITokenChange[];
  status: SolanaTransactionStatus;
}

export enum TokenToTransfer {
  SOLANA = "sol",
  USDC = "usdc",
}

export const BLOCKHASH_COMMITMENT: Commitment = "confirmed"

export interface ISolanaRpc {
  getPubkey: () => Promise<string>
  getLamportBalance: () => Promise<IChainBalance>
  getTokenBalance: (mint: PublicKey, decimals: number) => Promise<IChainBalance>
  getAssociatedTokenBalances: () => Promise<IChainBalance[]>
  signMessage: (message: Uint8Array) => Promise<Uint8Array>
  sendAndConfirmTransaction: (
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhash?: IRecentBlockhash,
    commitment?: Commitment | undefined
  ) => Promise<string>
  sendTransaction: (
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhash?: IRecentBlockhash,
    lookupTable?: PublicKey
  ) => Promise<string>
  signTransaction: (transaction: Transaction) => Promise<Transaction>
}

export default class SolanaRpc implements ISolanaRpc {
  private provider: SafeEventEmitterProvider;
  private client: Connection;
  private writeClients: Connection[];
  wallet: SolanaWallet;
  pubkey?: PublicKey;

  constructor(provider: SafeEventEmitterProvider, client: Connection) {
    this.provider = provider;
    this.wallet = new SolanaWallet(this.provider);
    this.client = client;
    this.writeClients = RPC_WRITE_ENPOINTS.map((connection) => {
      return new Connection(connection, 'processed')
    })
  }

  getPubkey = async (): Promise<string> => {
    if (this.wallet == null) {
      return null;
    }
    const pubkeys = await this.wallet.requestAccounts();

    return pubkeys[0];
  };

  getLamportBalance = async (): Promise<IChainBalance> => {
    const accounts = await this.wallet.requestAccounts();
    const userWallet = new PublicKey(accounts[0]);

    return await loadLamportBalance(userWallet, this.client)
  };

  getTokenBalance = async (mint: PublicKey, decimals: number): Promise<IChainBalance> => {
    const pubkey = await this.getPubkey();
    const userWallet = new PublicKey(pubkey);
    
    return await loadTokenBalance(userWallet, mint, decimals, this.client)
  };

  getAssociatedTokenBalances = async (): Promise<IChainBalance[]> => {
    const userWallet = await this.getPubkey();
    const client = this.client

    return await loadAssociatedTokenBalances(userWallet, client)
  };

  signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
    return await this.wallet.signMessage(message);
  };

  sendAndConfirmTransaction = async (
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhash?: IRecentBlockhash,
    commitment: Commitment | undefined = "processed"
  ): Promise<string> => {
    try {
      const latestBlockHash = blockhash != null ? blockhash : await client.getLatestBlockhash(BLOCKHASH_COMMITMENT);
      const signature = await this.sendTransaction(
        transaction,
        client,
        feePayer,
        errorByCodeByProgram,
        latestBlockHash,
      );

      await confirmTransaction(signature, client, latestBlockHash, commitment)

      return signature;
    } catch (err: any) {
      return Promise.reject(err);
    }
  };

  sendTransaction = async (
    transaction: Transaction,
    client: Connection,
    feePayer: PublicKey,
    errorByCodeByProgram: Map<string, Map<number, IdlErrorCode>>,
    blockhash?: IRecentBlockhash,
    lookupTable: PublicKey = ZEEBIT_LOOKUP_TABLE
  ): Promise<string> => {
    return await handleSendTransaction(
      this.writeClients,
      this.wallet,
      transaction,
      client,
      feePayer,
      errorByCodeByProgram,
      blockhash,
      lookupTable
    )
  };

  signTransaction = async (transaction: Transaction): Promise<Transaction> => {
    return await this.wallet.signTransaction(transaction);
  };
}
