import { defaultNetworkUrl } from "../chain/network";
import axios, { HttpStatusCode } from "axios";

interface SlotResult {
  numTransactions: number;
  samplePeriodSecs: number;
}

export async function calculateSolanaTPS(): Promise<number> {
  try {
    const res = await axios.post(defaultNetworkUrl, {
      jsonrpc: "2.0",
      id: 1,
      method: "getRecentPerformanceSamples",
      params: [4],
    });

    let tps = 0;
    const slotResults: SlotResult[] = res && res.data && res.data.result ? res.data.result : [];

    slotResults.forEach((result: SlotResult) => {
      tps += result.numTransactions / result.samplePeriodSecs / slotResults.length;
    });

    return tps;
  } catch (error) {
    console.error("There was an error estimating the Solana TPS");
    console.error(error);
    return 0;
  }
}

export async function checkNetworkStatus(): Promise<HttpStatusCode> {
  try {
    const res = await axios.post(defaultNetworkUrl, {
      jsonrpc: "2.0",
      id: "1",
      method: "getHealth",
    });
    return res.status;
  } catch (error) {
    console.error(error);
    return 500;
  }
}
