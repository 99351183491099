import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";
import { IBetMeta } from "../contexts/BetstreamingContext";
import { IGameHistory, IRandomnessResponse } from "./types";
import { IPlatformRank } from "../contexts/PlatformContext";
import { ITokenCheckMeta } from "../contexts/BalanceContext";
import House from "./house";
import { NetworkType, defaultNetwork } from "../utils/chain/network";

export interface IGameMeta {
  gameResult: IGameHistory | undefined;
  bets: IBetMeta[];
}

export interface IClaimableTotal {
  valueBase?: number,
  valueUsd?: number,
  tokenAmountSpread?: number,
  tokenAmountUpFront?: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  tokenIcon?: string
}

export enum ClaimableStatus {
  NOTHING_CLAIMED = 'nothingClaimed',
  NOTHING_TO_CLAIM = 'nothingToClaim',
  ACCRUING = 'accruing',
  CLAIMABLE = 'claimable',
  FOREFIT = 'forefit',
  CLAIMED = 'claimed'
}

export interface IClaimable {
  token: string,
  type: string,
  valueBase: number,
  tokenAmountSpread: number,
  tokenAmountUpFront: number,
  valueBaseUi?: number,
  valueUsdUi?: number,
  tokenAmountSpreadUi?: number,
  tokenAmountUpFrontUi?: number,
  spreadDays?: number
  tokenIcon?: string
  status?: ClaimableStatus
  startDate: Date,
  endDate: Date,
  tooltip?: string
}

export interface IClaimableMeta {
  totals: IClaimableTotal,
  claimables: IClaimable[]
  startDay: Date
  status: ClaimableStatus
}

export enum CollectableStatus {
  NOTHING_COLLECTED = 'nothingCollected',
  NOTHING_TO_COLLECT = 'nothingToCollect',
  COLLECTABLE = 'collectable',
  COLLECTABLE_IN_FUTURE = 'collectableInFuture',
  FOREFIT = 'forefit',
  COLLECTED = 'collected'
}

export interface ICollectable {
  amount: number
  token: string
  amountUi: number
  amountUsdUi: number
  startDay?: Date
  status?: CollectableStatus
  remaining?: {
    amount: number
    token: string
    amountUi: number
    amountUsdUi: number
    numberDays: number
  } | undefined
  tooltip?: string
}

export interface IRewardTransactionMeta {
  rakebackBoost?: {
    boostRate: number
    boostUntil: Date
  },
  levelUp?: {
    newRankId: number
    benefits: object
    newRank?: IPlatformRank
    valueBaseUi?: number
    valueUsdUi?: number
    tokenIcon: string
    claimable?: IClaimable | undefined
  },
  collected?: ICollectable,
  claimed?: IClaimable[]
  claimedTotals?: IClaimableTotal
  referral?: IClaimable
}

export default class BetStream {
  private _program: Program;
  private _eventParser: anchor.EventParser;
  private _randomnessProgram: Program;
  private _randomnessParser: anchor.EventParser; // USED TO CHECK FOR RANDOMNESS PROOF
  private _cashierProgram: Program;
  private _cashierParser: anchor.EventParser; // USED TO CHECK FOR RANDOMNESS PROOF

  constructor(
    casinoProgram: anchor.Program,
    randomnessProgram: anchor.Program,
    cashierProgram: anchor.Program
  ) {
    this._program = casinoProgram;
    this._eventParser = new anchor.EventParser(
      this.program.programId,
      new anchor.BorshCoder(this.program.idl),
    );
    this._randomnessProgram = randomnessProgram;
    this._randomnessParser = new anchor.EventParser(
      randomnessProgram.programId,
      new anchor.BorshCoder(randomnessProgram.idl),
    );
    this._cashierProgram = cashierProgram;
    this._cashierParser = new anchor.EventParser(
      cashierProgram.programId,
      new anchor.BorshCoder(cashierProgram.idl),
    );
  }

  get program() {
    return this._program;
  }

  get eventParser() {
    return this._eventParser;
  }

  async loadHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfBets: number = 10,
    filter?: Function,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    let lastTransactionSeen = null;
    let numberOfBets = 0;
    let finished = false;

    let metas: IBetMeta[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfBets && finished == false) {
      try {
        let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

        // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

        if (transactionSignatures.length <= 50) {
          finished = true;
        }

        transactionSignatures = transactionSignatures.slice(0, 50);

        const transactionLogs = await this.program.provider.connection.getParsedTransactions(
          transactionSignatures.map((txnSig) => txnSig.signature),
          {
            maxSupportedTransactionVersion: 0,
            commitment: finalityLevel
          },
        );

        transactionLogs.forEach((txnLogs) => {
          const bets = this.parseTxLogs(txnLogs);
          const betsFiltered = bets.filter((meta) => {
            if (filter != null) {
              return filter(meta) && meta.gameResult != null && (!['slotsThree'].includes(Object.keys(meta.gameResult.gameType)[0])  || defaultNetwork != NetworkType.MAINNET);
            } else {
              return meta.betResult != null && meta.gameResult != null && (!['slotsThree'].includes(Object.keys(meta.gameResult.gameType)[0]) || defaultNetwork != NetworkType.MAINNET);
            }
          });

          if (betsFiltered.length > 0) {
            metas.push(...betsFiltered);
            numberOfBets = metas.length;
          }
        });

        if (numberOfBets >= maxNumberOfBets) {
          break;
        }

        if (iteration >= maxIterations) {
          break;
        }

        if (transactionSignatures.length > 0) {
          lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
        }

        iteration += 1;
      } catch (err) {
        console.error("Issue with betstream", { err })
        finished = true
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.betResult?.timestamp * 1000) - new Date(b.betResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfBets) {
      metas = metas.slice(-maxNumberOfBets);
    }

    return metas;
  }

  async loadGameHistory(
    pubkeyFilter: PublicKey, // gameInstance, gameSpec, player, casinoProgram...
    maxNumberOfGames: number = 10,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    var lastTransactionSeen = null;
    var numberOfBets = 0;
    var finished = false;

    let metas: IGameMeta[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfBets < maxNumberOfGames && finished == false) {
      let transactionSignatures = await this.program.provider.connection.getSignaturesForAddress(
        pubkeyFilter,
        {
          before: lastTransactionSeen,
        },
        finalityLevel,
      );

      // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

      if (transactionSignatures.length <= 50) {
        finished = true;
      }

      transactionSignatures = transactionSignatures.slice(0, 50);

      const transactionLogs = await this.program.provider.connection.getParsedTransactions(
        transactionSignatures.map((txnSig) => txnSig.signature),
        {
          maxSupportedTransactionVersion: 0,
          commitment: finalityLevel
        },
      );

      transactionLogs.forEach((txnLogs) => {
        const gameMeta = this.toGameMeta(txnLogs);

        if (gameMeta.gameResult != null && gameMeta.bets.length > 0) {
          metas = [...metas].concat(...[gameMeta]);
          numberOfBets = metas.length;
        }
      });
      if (transactionSignatures.length != 0) {
        lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
      }

      if (numberOfBets >= maxNumberOfGames) {
        break;
      }

      iteration += 1;
      if (iteration >= maxIterations) {
        break;
      }
    }

    // Sort the lists in chronological order and cap it at the newest [max] number of records

    metas = metas.sort(
      (a, b) => new Date(a.gameResult?.timestamp * 1000) - new Date(b.gameResult?.timestamp * 1000),
    );

    if (metas.length > maxNumberOfGames) {
      metas = metas.slice(-maxNumberOfGames);
    }

    return metas;
  }

  async loadBet(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    const bets = this.parseTxLogs(transactionLogs);
    const betsFiltered = bets.filter((meta) => {
      return meta.betResult != null && meta.gameResult != null;
    });

    return betsFiltered;
  }

  async loadRandomness(signature: string, finalityLevel: anchor.web3.Finality = "confirmed") {
    const transactionLogs = await this.program.provider.connection.getParsedTransaction(
      signature,
      {
        commitment: finalityLevel,
        maxSupportedTransactionVersion: 0
      },
    );

    const randomness = this.parseTxLogsForRandomness(transactionLogs);

    return randomness;
  }

  parseTxLogs(txnLogs: any) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null when parsing...`)
      return []
    }

    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const transactionSignature = txnLogs?.transaction.signatures[0];

    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of events) {

      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId.toNumber()}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return betMetas.map((meta) => {
      meta.gameResult = gameResult;

      return meta;
    });
  }

  toGameMeta(txnLogs: any): IGameMeta {
    const betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const transactionSignature = txnLogs?.transaction.signatures[0];
    const events = this.eventParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return {
      gameResult: gameResult,
      bets: betMetas.map((meta) => {
        meta.gameResult = gameResult;

        return meta;
      }),
    };
  }

  parseTxLogsForCashier(txnLogs: any, filter?: Function) {
    if (txnLogs == null) {
      console.warn(`Txn Logs are null in parseTxLogsForCashier`)
      return []
    }
    const randomEvents = this._cashierParser.parseLogs(txnLogs.meta.logMessages);
    const parsedEvents: { name: string; timestamp: string; data: string }[] = [];

    for (let event of randomEvents) {
      if (filter != null) {
        const passesFilter = filter(event);

        if (passesFilter == true) {
          parsedEvents.push({
            name: event.name,
            timestamp: new Date(event.data.timestamp.toNumber() * 1000).toISOString(),
            data: JSON.stringify(event.data),
          });
        }
      } else {
        parsedEvents.push({
          name: event.name,
          timestamp: new Date(event.data.timestamp.toNumber() * 1000).toISOString(),
          data: JSON.stringify(event.data),
        });
      }
    }

    return parsedEvents;
  }

  parseTxLogsForRandomness(txnLogs: any) {
    let randomnessResult: IRandomnessResponse | undefined = undefined;
    const randomEvents = this._randomnessParser.parseLogs(txnLogs.meta.logMessages);

    for (let event of randomEvents) {
      // RANDOMNESS RESPONSE EVENT
      if ((event.name = "ResponseEvent")) {
        randomnessResult = event.data;
      }
    }

    return randomnessResult;
  }

  parseNewLog(txnLogs: any): IBetMeta[] {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return betMetas.map((bm) => {
      bm.gameResult = gameResult;

      return bm;
    });
  }

  toNewGameMeta(txnLogs: any): IGameMeta {
    let betMetas: IBetMeta[] = [];
    let gameResult: IGameHistory | undefined = undefined;

    const events = this.eventParser.parseLogs(txnLogs.logs);
    const transactionSignature = txnLogs.signature;
    for (let event of events) {
      if (event.name == "BetResultEvent") {
        event.data["signature"] = transactionSignature;

        betMetas.push({
          betResult: event.data,
          id: `${transactionSignature}-${event.data.betId}`,
          gameResult: undefined,
        });
      } else if (event.name == "GameInstanceResultEvent") {
        event.data["signature"] = transactionSignature;

        gameResult = event.data;
      }
    }

    return {
      bets: betMetas.map((bm) => {
        bm.gameResult = gameResult;

        return bm;
      }),
      gameResult: gameResult,
    };
  }

  async loadCashierHistory(
    pubkeyFilter: PublicKey, // reward calendar, player account...
    filter?: Function,
    maxNumberOfRecords: number = 10,
    finalityLevel: anchor.web3.Finality = "confirmed",
  ) {
    var lastTransactionSeen = null;
    var numberOfRecords = 0;
    var finished = false;

    let metas: any[] = [];

    let iteration = 0;
    let maxIterations = 5;

    while (numberOfRecords < maxNumberOfRecords && finished == false) {
      let transactionSignatures =
        await this._cashierProgram.provider.connection.getSignaturesForAddress(
          pubkeyFilter,
          {
            before: lastTransactionSeen,
          },
          finalityLevel,
        );

      // TAKE THE FIRST 50 TXNS - OTHERWISE REQUEST TOO LARGE on getParsedTransactions

      if (transactionSignatures.length <= 50) {
        finished = true;
      }

      transactionSignatures = transactionSignatures.slice(0, 50);

      const transactionLogs = await this._cashierProgram.provider.connection.getParsedTransactions(
        transactionSignatures.map((txnSig) => txnSig.signature),
        {
          maxSupportedTransactionVersion: 0,
          commitment: finalityLevel
        },
      );

      transactionLogs.forEach((txnLogs) => {
        const events = this.parseTxLogsForCashier(txnLogs, filter);

        if (events != null && events.length > 0) {
          metas = metas.concat(events);
          numberOfRecords = metas.length;
        }
      });
      if (transactionSignatures.length != 0) {
        lastTransactionSeen = transactionSignatures[transactionSignatures.length - 1].signature;
      }

      if (numberOfRecords >= maxNumberOfRecords) {
        break;
      }

      iteration += 1;
      if (iteration >= maxIterations) {
        break;
      }
    }

    return metas;
  }

  get randomnessParser() {
    return this._randomnessParser
  }

  get cashierParser() {
    return this._cashierParser
  }

  static toClaimedTotals(claimables: IClaimable[], house: House, tokenByIdentifier: Map<string, ITokenCheckMeta>): IClaimableTotal {
    let totals: IClaimableTotal = {
      valueBase: 0,
      valueUsd: 0,
      tokenAmountSpread: 0,
      tokenAmountUpFront: 0,
      valueBaseUi: 0,
      valueUsdUi: 0,
      tokenAmountSpreadUi: 0,
      tokenAmountUpFrontUi: 0,
      tokenIcon: ''
    }

    if (claimables == null || claimables.length == 0) {
      return totals
    }

    let token: PublicKey | null

    claimables.forEach((claimable) => {
      if (token == null) {
        token = new PublicKey(claimable.token)
      }
      totals.valueBase += (claimable.valueBase || 0)
      totals.tokenAmountSpread += (claimable.tokenAmountSpread || 0)
      totals.tokenAmountUpFront += (claimable.tokenAmountUpFront || 0)
      totals.valueBaseUi += (claimable.valueBaseUi || 0)
      totals.tokenAmountSpreadUi += (claimable.tokenAmountSpreadUi || 0)
      totals.tokenAmountUpFrontUi += (claimable.tokenAmountUpFrontUi || 0)
    })

    const tokenContext = tokenByIdentifier.get(token?.toString())
    totals.tokenIcon = tokenContext?.context?.imageDarkPng

    totals.valueUsdUi = house.approximateTokenAmountToBase(token, totals.valueBaseUi || 0)

    return totals
  }
}
