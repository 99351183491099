import * as anchor from "@coral-xyz/anchor";
import { PublicKey } from "@solana/web3.js";

import Platform from "./platform";

interface IPlatformRanksState {
  platform: PublicKey;
  rankBenefits: object[];
  rankThresholds: anchor.BN[];
}

export interface IRankBenefit {
  dailyBonusAccrualRatePerThousand: number;
  defaultRakebackRatePerThousand: number;
  levelUpBonusAccrualRatePerThousand: number;
  monthlyBonusAccrualRatePerThousand: number;
  rakebackBoostDurationSeconds: number;
  rakebackBoostPerThousand: number;
  weeklyBonusAccrualRatePerThousand: number;
}

export class PlatformRanks {
  private _platform: Platform;
  private _publicKey: PublicKey;
  private _state: any;
  private _stateLoaded: boolean;

  constructor(platform: Platform, pubkey: PublicKey) {
    this._stateLoaded = false;
    this._platform = platform;
    this._publicKey = pubkey;
  }

  static async load(
    platform: Platform,
    pubkey: PublicKey,
    commitmentLevel: anchor.web3.Commitment = "processed",
  ) {
    const platformRanks = new PlatformRanks(platform, pubkey);
    await platformRanks.loadState(commitmentLevel);
    return platformRanks;
  }

  async loadState(commitmentLevel: anchor.web3.Commitment = "processed") {
    const state = await this._platform.program.account.platformRanks.fetchNullable(
      this._publicKey,
      commitmentLevel,
    );
    this._state = state;
    this._stateLoaded = true;
    return;
  }

  get platform() {
    return this._platform;
  }

  get rankBenefits(): IRankBenefit[] | null {
    return this.state != null
      ? this.state.rankBenefits.map((benefit) => {
          // BENEFIT like {v0: {...}}, returning {...}
          return Object.values(benefit)[0];
        })
      : null;
  }

  get rankThresholds(): number[] | null {
    return this.state != null
      ? this.state.rankThresholds.map((threshhold) => {
          return threshhold.toNumber();
        })
      : null;
  }

  get stateLoaded(): boolean {
    return this._stateLoaded;
  }

  get state(): IPlatformRanksState {
    return this._state;
  }
}
